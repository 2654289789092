// VisibilityManager.js
'use strict';

/** Class visibility manager
 * refer to: https://stereologics.wordpress.com/2015/04/02/about-page-visibility-api-hidden-visibilitychange-visibilitystate/
 * execute visibleListener or hiddenListener when switching between tabs and switching between applications
 */
class VisibilityManager {
  /**
   * Create visibility manger
   * @param {object} options
   * @param {() => void} options.visibleListener
   * @param {() => void} options.hiddenListener
   */
  constructor({ visibleListener, hiddenListener } = {}) {
    this.isVisible = !document.hidden;
    this.visibleListeners = new Set();
    this.hiddenListeners = new Set();
    if (visibleListener) this.visibleListeners.add(visibleListener);
    if (hiddenListener) this.hiddenListeners.add(hiddenListener);
    this.bindListeners();
  }

  addVisibleListener(listener) {
    this.visibleListeners.add(listener);
  }

  removeVisibleListener(listener) {
    this.visibleListeners.delete(listener);
  }

  addHiddenListener(listener) {
    this.hiddenListeners.add(listener);
  }

  removeHiddenListener(listener) {
    this.hiddenListeners.delete(listener);
  }

  handleVisibilityChange = ({ fromEvent } = {}) => {
    if (fromEvent === 'focus') return this.onVisible();
    else if (fromEvent === 'blur') return this.onHidden();

    if (document.hidden) return this.onHidden();
    return this.onVisible();
  };

  handleFocus = () => {
    this.handleVisibilityChange({ fromEvent: 'focus' });
  };

  handleBlur = () => {
    this.handleVisibilityChange({ fromEvent: 'blur' });
  };

  onVisible() {
    if (this.isVisible) return;

    this.isVisible = true;
    this.visibleListeners.forEach(listener => listener());
  }

  onHidden() {
    if (!this.isVisible) return;

    this.isVisible = false;
    this.hiddenListeners.forEach(listener => listener());
  }

  bindListeners() {
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    );
    document.addEventListener('focus', this.handleFocus, false);
    document.addEventListener('blur', this.handleBlur, false);
    window.addEventListener('focus', this.handleFocus, false);
    window.addEventListener('blur', this.handleBlur, false);
  }

  unbindListeners() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
    document.removeEventListener('focus', this.handleFocus);
    document.removeEventListener('blur', this.handleBlur);
    window.removeEventListener('focus', this.handleFocus);
    window.removeEventListener('blur', this.handleBlur);
  }
}

export default VisibilityManager;
