// setServiceWorker.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

const path = ['serviceWorker'];
/**
 * Set service worker
 * @kind action
 * @param {boolean} {isUsing} - is using service worker flag.
 * @return {Promise} Action promise.
 */
const setServiceWorker =
  ({ isUsing }) =>
  async dispatch => {
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: { selectPath: path, data: { isUsing } },
    });
  };

export default setServiceWorker;
