// unRegisterServiceWorker.js
'use strict';

/**
 * Unregister service worker
 */
const unRegisterServiceWorker = () =>
  navigator.serviceWorker
    ?.getRegistrations()
    .then(registerations =>
      Promise.all(
        registerations.map(registeration => registeration.unregister())
      )
    );
export default unRegisterServiceWorker;
