// FallbackServiceWorker.js
'use strict';
import * as universalServiceWorker from '../serviceWorker/universalServiceWorker.js';
import { serviceWorkerLog, serviceWorkerError } from '../resource/debug.js';
import WorkerMessenger from '../resource/WorkerMessenger.js';

/**
 * FallbackServiceWorker is to run our service worker on main thread..
 */
class FallbackServiceWorker {
  /**
   * Create a FallbackServiceWorker.
   * @param {EventTarget} {workerMessengerArguments.listenTarget} - Target to listen interface calling result and peer interface events.
   * @param {string} {workerMessengerArguments.messageIdKey} - messageIdKey for internal worker messenger
   */
  constructor({ workerMessengerArguments: { listenTarget, messageIdKey } }) {
    this.log = serviceWorkerLog.extend('FallbackServiceWorker');
    this.errorLog = serviceWorkerError.extend('FallbackServiceWorker');

    if (!listenTarget || !listenTarget.addEventListener) {
      throw new Error('missing listenTarget on creating FallbackServiceWorker');
    }

    this.eventTarget = new EventTarget();

    this.workerMessenger = new WorkerMessenger({
      name: 'fallbackWorkerMessenger',
      scope: this,
      messageIdKey,
      getMessageTarget: async () => this,
      listenTarget,
    });

    Object.keys(universalServiceWorker).forEach(key => {
      const target = universalServiceWorker[key];
      if (typeof target === 'function') {
        this[key] = target.bind(this);
      } else {
        this[key] = target;
      }
    });

    this.log('contructor finish', {
      serviceWorker: this,
      workerMessengerArguments: {
        listenTarget,
      },
    });
  }

  postMessage(message) {
    const event = new CustomEvent('message', { detail: message });
    this.log('postMessage', { message, event });
    this.eventTarget.dispatchEvent(event);
  }

  static instance = null;

  static getInstance = ({ workerMessengerArguments }) => {
    const log = serviceWorkerLog.extend('getInstance');
    const errorLog = serviceWorkerError.extend('getInstance');
    if (!this.instance) {
      try {
        log('getInstance()', { workerMessengerArguments });
        this.instance = new FallbackServiceWorker({ workerMessengerArguments });
      } catch (error) {
        errorLog('getInstance() error', { error, workerMessengerArguments });
      }
    }
    return this.instance;
  };
}

export default FallbackServiceWorker;
